var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_vm._v(" alo " + _vm._s(_vm.item) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }