import { ref } from '@vue/composition-api'
import { cloneDeep, isEmpty } from 'lodash'

import { getDifference } from '@/@core/utils/utils'
import { apiSupportRequest } from '@/api'

import useToast from '@useToast'

export default function useDetailHandle() {
  const { toastSuccess, toastError } = useToast()
  const blankReq = {
    pnr: '',
    phone: '',
    content: '',
    note: '',
    airlineCode: '',
    status: 'PENDING',
  }
  // Loading
  const loading = ref(true)
  const createReq = ref(cloneDeep(blankReq))
  const updateReq = ref(cloneDeep(blankReq))
  const item = ref(cloneDeep(blankReq))

  async function createHandle() {
    try {
      const response = await apiSupportRequest.create(createReq.value)
      toastSuccess('supportReq.createSuccess')
      return response
    } catch (error) {
      toastError(error)
      throw error
    }
  }

  async function updateHandle() {
    try {
      const req = getDifference(updateReq.value, item.value)
      if (isEmpty(req)) {
        return
      }
      await apiSupportRequest.update(updateReq.value.id, req)
      toastSuccess('supportReq.updateSuccess')
    } catch (error) {
      toastError(error)
      throw error
    }
  }
  async function onLoadHandle(id) {
    try {
      loading.value = true
      if (id) {
        const response = await apiSupportRequest.getById(id)
        Object.assign(updateReq.value, response)
        Object.assign(item.value, response)
      } else {
        createReq.value = cloneDeep(blankReq)
      }
      loading.value = false
    } catch (error) {
      toastError(error)
      throw error
    }
  }

  return {
    // Loading
    loading,
    item,
    createReq,
    updateReq,

    createHandle,
    updateHandle,
    onLoadHandle,
  }
}
