<template lang="">
  <IAmOverlay :loading="loading">
    alo
    {{ item }}
  </IAmOverlay>
</template>
<script>
import {
  BButton,
  BCol, BRow,
} from 'bootstrap-vue'

import router from '@/router'

import useDetailHandle from './useDetailHandle'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup() {
    const {
      loading,
      item,
      onLoadHandle,
    } = useDetailHandle()
    onLoadHandle(router.currentRoute.params.id)
    return {
      loading,
      item,
      onLoadHandle,
    }
  },
}
</script>
<style lang="scss" scoped>
#invoice-create ::v-deep {
  .form-group {
    margin-bottom: 0.25rem;
    label,
    legend {
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 1rem;
      // white-space: nowrap;
    }
  }
}
</style>
